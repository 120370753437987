<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		v-if="!loading"
		class="row no-gutters justify-content-center">
		<div
			v-if="!!description"
			class="col-12">
			<div class="my-2">
				<b-alert
					variant="info"
					class="m-0"
					show
					v-html="description" />
			</div>
		</div>
		<div
			:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-2'"
			class="col-12">
			<div class="row mx-2 mt-1">
				<div class="col-12">
					<!-- Parent skus -->
					<div
						v-for="(item, key) in configurableProducts"
						:key="item.attributes.sku"
						:class="key < configurableProducts.length - 1 ? 'border border-secondary border-top-0 border-left-0 border-right-0 mb-2' : ''"
						class="row">
						<div class="col">
							<configurable-product
								:code-name="item.attributes.code_name"
								:sku="item.attributes.sku"
								:config-name="'flavor'"
								:thumbnail="item.attributes.thumbnail"
								:stacked-layout="['xs', 'sm'].includes(windowWidth)">
								<!-- Parent children -->
								<!--{{ item.attributes.children }}-->
								<div
									class="row"
									style="row-gap: 10px">
									<div
										v-for="(child, sku) in item.attributes.children"
										:key="child.code_name"
										class="col-12 col-md-6">
										<product-configuration
											:price="child.price.formatted_amount"
											:exchange="child.exchange"
											:exchange-price="child.exchange_price.formatted_amount"
											:bvs="showBvs ? Number(child.bvs) : null"
											:backorder-info="showBackorderInfo ? child.backorder_info : null"
											:configuration="child.code_name"
											:init-qty="selected[sku].value || 0"
											:stacked-layout="['xs', 'sm'].includes(windowWidth)"
											@change="handleChange(sku, $event)" />
									</div>
								</div>
							</configurable-product>
						</div>
					</div>
				</div>
				<div class="col-12 border border-secondary border-bottom-0 border-left-0 border-right-0 pt-2 mt-1">
					<div class="row mb-3">
						<div
							:class="['xs', 'sm'].includes(windowWidth) ? 'pl-0' : ''"
							class="col-12">
							<div class="row">
								<div
									v-for="item in simpleProducts"
									:key="item.attributes.sku"
									class="col-12 col-md-6">
									<simple-product
										:price="item.attributes.price.formatted_amount"
										:exchange="item.attributes.exchange"
										:exchange-price="item.attributes.exchange_price.formatted_amount"
										:backorder-info="showBackorderInfo ? item.attributes.backorder_info : null"
										:code-name="item.attributes.code_name"
										:bvs="showBvs ? Number(item.attributes.bvs) : null"
										:init-qty="selected[item.attributes.sku].value || 0"
										:thumbnail="item.attributes.thumbnail"
										:stacked-layout="['xs', 'sm'].includes(windowWidth)"
										@change="handleChange(item.attributes.sku, $event)" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProductConfiguration from '@/components/AdditionalProductsModal/ProductConfiguration.vue';
import ConfigurableProduct from '@/components/AdditionalProductsModal/ConfigurableProduct.vue';
import SimpleProduct from '@/components/AdditionalProductsModal/SimpleProduct.vue';
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ProductList',
	components: { SimpleProduct, ConfigurableProduct, ProductConfiguration },
	mixins: [ConfigurableProducts, WindowSizes],
	props: {
		description: {
			type: String,
			default: '',
		},
		configurableProducts: {
			type: Array,
			default: () => [],
		},
		simpleProducts: {
			type: Array,
			default: () => [],
		},
		selectedProducts: {
			type: Object,
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showBackorderInfo: {
			type: Boolean,
			default: false,
		},
		showBvs: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			selected: this.selectedProducts,
		};
	},
	watch: {
		selectedProducts: {
			handler(newVal) {
				this.selected = newVal;
			},
			deep: true,
		},
	},
	methods: {
		handleChange(sku, qty) {
			this.$set(this.selected[sku], 'value', qty);
			this.$emit('update:selectedProducts', this.selected);
		},
	},
};
</script>

<style scoped>

</style>
